import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

function success() {
  return (
    <Layout>
      <div className="pageContainer success">
        <div className="success-text center">
          <h1>Awesome</h1>
          <p>Your Submission Was Received </p>
          <p> I'll get back to you shortly !</p>
        </div>
        <div className="cta">
          <Link to="/">
            <button className="cta-action">Go Back Home</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default success;
